<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_subcategory'})">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">Need a little help?</h1>
            <p>We can automatically build your Listing using information publicly available from selected social accounts.</p>

            <b-button variant="primary" class="my-2 p-2" disabled>
              <feather-icon icon="PlusIcon"></feather-icon>  
              Add a connection
            </b-button>

            <b-form-radio-group v-model="selected_radio">
              <b-col 
                class="col-12 mt-1 mx-auto" 
                v-for="(network, index) in profile.networks" 
                :key="index"
                ref="card_radio_button"
                @click="getActive(network, index)"
              >
                <div class="card-radion-button-steps-networks d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <b-avatar :src="utils.getImageNetworkColor(network.network)" class="bg-white mr-1"></b-avatar>
                    <div class="text-left">
                      <strong>{{utils.capitalize(network.network)}}</strong>
                      <span class="text-muted d-block mt-1"> {{getUrlNetwork(network)}}</span>
                    </div>
                  </div>
                  <b-form-radio class="float-right" :value="`radio_${index}`"></b-form-radio>
                </div>
              </b-col>
            </b-form-radio-group>
            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="primary" class="my-2" :disabled="selected === null" @click="save()">Next step</b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BAvatar,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import utils from '@/libs/utils';
import service_others from '@/services/others';

export default {
  name: 'enrichmentConnection',
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BAvatar,
    BFormRadioGroup,
    BFormRadio,
    layoutStepper,
  },
  data() {
    return {
      selected: null,
      profile: {
        networks: []
      },
      utils,
      steps: null,
      index_actived: 0,
      selected_radio: null,
    }
  },
  created() {
    this.getProfile()
  },
  methods: {
    getActive(value, index) {
      this.clearActive();
      this.$refs.card_radio_button[index].classList.add('active-class-button');
      this.selected = value;
      this.index_actived = index;
      this.selected_radio = `radio_${index}`
    },
    clearActive() {
      for (let index = 0; index < this.profile.networks.length; index++) {
        this.$refs.card_radio_button[index].classList.remove('active-class-button')
      }
    },
    getData() {
      this.steps = JSON.parse(localStorage.getItem('steps'));
      if (this.steps.network_selected) {
        setTimeout(() => {
          this.getActive(this.steps.network_selected, this.steps.index_actived[2])
        }, 200);
      }
    },
    save() {
      this.steps['network_selected'] = this.selected;
      this.steps.index_actived[2] = this.index_actived;
      localStorage.setItem('steps', JSON.stringify(this.steps));
      this.$router.push({name: 'step_title'})
    },
    getProfile() {
      const user = JSON.parse(localStorage.userData);
      service_others.getProfile().then((response) => {
        this.profile = response.user;
        utils.setuserData(response, user.ability);
        this.getData()
      });
    },
    getUrlNetwork(net) {
      return utils.getURL(net.network, net.username, (net.network === 'youtube'))
    }
  },
}
</script>
<style>
.card-radion-button-steps-networks {
  border-radius: 1em;
  background-color: white;
  overflow: hidden !important;
  padding: 1em;
  border: 1px solid rgba(210, 210, 210, 0.7); 
  position: relative;
}
.card-radion-button-steps-networks:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  transition: 500ms;
}
.active-class-button > .card-radion-button-steps-networks {
  background-color: #7267f01b !important;
  border: 1px solid #7367f0;
}
</style>