<template>
  <div class="mt-1">
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {
  name: 'layoutStepper',
  created() {
    setTimeout(() => {
      document.getElementsByClassName('nav-hover-steps')[0].classList.remove('d-none');
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
      this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });
    }, 5)
  },
  destroyed() {
    if (this.$route.name === 'my_work') document.getElementsByClassName('nav-hover-steps')[0].classList.add('d-none')
  }
}
</script>